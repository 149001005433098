#splash-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

#splash {
  min-height: 100vh;
  max-width: 1100px;
  padding: 0 20px;
}

#splash-hello {
  font-size: 1.5rem;
}

#splash-name {
  margin: 40px 0;
  font-size: 4rem;
}

#splash-desc {
  line-height: 25px;
  margin-bottom: 50px;
  font-size: 0.85rem;
  width: 70%;
}

#splash-desc span:nth-of-type(1) {
  background: var(--yellow);
  color: var(--blue);
  padding: 0 5px;
}
#splash-desc span:nth-of-type(2) {
  background: var(--green);
  color: var(--yellow);
  padding: 0 5px;
}
#splash-desc span:nth-of-type(3) {
  background: var(--sub-green);
  color: var(--blue);
  padding: 0 5px;
}

#splash-school-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.splash-school {
  display: flex;
  align-items: center;
  padding: 15px 0;
  font-weight: 900;
  font-size: 0.75rem;
}

.splash-school svg {
  padding-right: 15px;
}

/* sketches */

#computer {
  animation: floating 4s ease-in-out infinite;
  position: absolute;
  top: 10px;
  right: 100px;
}

#note-one {
  animation: floating 3s ease-in-out infinite;
  position: absolute;
  top: 80px;
  right: 50px;
}

#note-two {
  animation: floating 3.5s ease-in-out infinite;
  position: absolute;
  top: 0px;
  right: 50px;
}

#note-three {
  animation: floating 3.75s ease-in-out infinite;
  position: absolute;
  top: -20px;
  right: 10px;
}
#note-four {
  animation: floating 3.25s ease-in-out infinite;
  position: absolute;
  top: -40px;
  right: 40px;
}
#note-five {
  animation: floating 4.25s ease-in-out infinite;
  position: absolute;
  top: 100px;
  right: 100px;
}

#splash-drawings {
  position: relative;
  transform: translateY(-100px);
}

#splash-drawings svg path {
  fill: var(--yellow);
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

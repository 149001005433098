#calendar {
  width: 15%;
  padding-right: 40px;
}

.calendar-year {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
}
.calendar-item {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.calendar-year-text {
  font-weight: 900;
  font-style: italic;
  padding-right: 10px;
}

.calendar-large-dot {
  min-width: 15px;
  height: 15px;
  border: 2px solid var(--yellow);
  border-radius: 50%;
}
.calendar-small-dot {
  min-width: 5px;
  height: 5px;
  margin: 20px 8px;
  background: var(--yellow);
  border-radius: 50%;
}
